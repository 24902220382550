import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pincode" }
const _hoisted_2 = { class: "pincode__container" }
const _hoisted_3 = ["onKeydown", "onPaste", "onUpdate:modelValue", "disabled", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["pincode__container__input-box", {'pincode__container__input-box--error': _ctx.showErrorState}])
        }, [
          _withDirectives(_createElementVNode("input", {
            onKeydown: ($event: any) => (_ctx.inputKeyPress($event, index)),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputSelected($event.target.value))),
            onPaste: ($event: any) => (_ctx.paste($event, index)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.moveToInput())),
            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
            type: "text",
            autocomplete: "off",
            disabled: _ctx.disabled,
            ref_for: true,
            ref: `input-${index}`,
            maxlength: _ctx.maxLength
          }, null, 40, _hoisted_3), [
            [
              _vModelText,
              field.value,
              void 0,
              { trim: true }
            ]
          ])
        ], 2))
      }), 128))
    ])
  ]))
}