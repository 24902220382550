
import { defineComponent } from 'vue'
import { store } from '@libs/store'
import Device from '@/components/Device.vue'

export default defineComponent({
	name: 'HomeView',
	components: {
		Device
	},
	computed: {
		computedFailedNiceSupport() {
			const niceSupport = store.getData('featureSupport.nice') as Record<string, unknown>[]
			return niceSupport.filter((feature: Record<string, unknown>) => !feature.support)
		}
	}
})
