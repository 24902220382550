<template>
	<span class="count-up">
		{{ computedValue.formatted }}<slot />
	</span>
</template>

<script>
import easingsFunctions from '@libs/easings'

export default {
	name: 'CountUp',
	props: {
		counter: {
			type: Number,
			default: 0
		},
		speed: {
			type: Number,
			default: 1000,
			// Will be divided by 100: not 0 and result must be min. 1ms
			validator: (speed) => speed >= 100
		},
		formatter: {
			type: Function,
			default: null
		},
		intlNumberFormat: {
			type: Object,
			default: () => ({
				minimumFractionDigits: 1,
				maximumFractionDigits: 1
			})
		},
		intlLanguageCode: {
			type: String,
			default: 'da-DK',
			validator: (lang) => !!Intl.NumberFormat.supportedLocalesOf(lang).length
		},
		easing: {
			type: String,
			default: 'easeInOutCirc'
		}
	},
	data() {
		return {
			progress: 0,
			delta: 0,
			oldValue: 0,
			interval: 0
		}
	},
	computed: {
		computedSpeed() {
			return this.speed / 100
		},
		computedValue() {
			const val = this.oldValue + easingsFunctions[this.easing](this.progress / 100) * this.delta

			if (isNaN(val)) return { formatted: '-', val: null }

			if (val === this.counter) this.$emit('count-up', 'done')

			const formatted = this.formatter
				? this.formatter(val)
				: new Intl
					.NumberFormat(this.intlLanguageCode, this.intlNumberFormat)
					.format(val)

			return {
				value: val,
				formatted
			}
		}
	},
	watch: {
		counter: {
			handler(val, oldVal = 0) {
				if (isNaN(val)) return

				this.oldValue = oldVal
				this.progress = 0
				this.delta = val - oldVal

				clearInterval(this.interval)

				this.interval = setInterval(() => {
					this.progress++

					if (this.progress >= 100) clearInterval(this.interval)
				}, this.computedSpeed)
			},
			immediate: true
		}
	}
}
</script>
