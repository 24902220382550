
import { defineComponent } from 'vue'
import { Rotation } from '@libs/interfaces/interfaces'
import * as Highcharts from 'highcharts'
import createChart from '@/assets/libs/models/rotations'

export default defineComponent({
	name: 'InfoboardChart',
	props: {
		rotation: {
			type: Object as () => Rotation,
			required: true
		},
		locale: {
			type: String,
			default: 'en-GB'
		}
	},
	data() {
		return {
			chart: {} as Highcharts.Chart
		}
	},
	computed: {
		computedChart(): unknown {
			return createChart(this.rotation, this.locale)
		}
	},
	watch: {
		computedChart: {
			handler(chartData: Highcharts.Options) {
				this.$nextTick(() => { // ref is possibly undefined unless deferred
					const ref = this.$refs.chart as HTMLElement

					if (!(chartData && ref)) return

					ref.classList.remove('animation--fade')
					const options = Highcharts.merge(chartData, {
						chart: {
							renderTo: ref as HTMLElement
						}
					})

					this.chart = Highcharts.chart(options)
					this.chart.reflow()
					ref.classList.add('animation--fade')
				})
			},
			immediate: true,
			deep: true
		}
	}
})
