<template>
	<div class="stacked-icon-information">
		<div class="stacked-icon-information__icons">
			<FontAwesomeIcon
				:icon="['fal', 'circle']"
				class="icon--faded"
			/>
			<FontAwesomeIcon
				:icon="icon"
				class="icon--centered"
			/>
			<FontAwesomeIcon
				:icon="['fal', 'slash']"
				class="icon--centered icon--faded icon--left-adjusted"
			/>
		</div>
		<h6>
			<slot />
		</h6>
	</div>
</template>

<script>

export default {
	name: 'StackedIconInformation',
	props: {
		icon: {
			type: Array,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.stacked-icon-information {

		&__icons {
			display: inline-block;
			position: relative;
			font-size: 80px;

			.icon {
				&--centered {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate3d(-50%, -50%, 0);
					font-size: 35px;
				}
				&--faded {
					opacity: 0.5;
				}
				&--left-adjusted {
					transform: translate3d(calc(-50% - 3px), -50%, 0);
				}
			}
		}
		h6 {
			margin-top: 20px;
		}

		.fa-layers {
			font-size: 80px !important;
		}
	}
</style>
