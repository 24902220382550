import Highcharts from 'highcharts'
import { mergeSeries } from '@libs/models/charts/charts'
import { ComparisonEnum, ConsumptionType } from '../../interfaces/interfaces'
import { formatCurrency } from '@libs/models/formatters'
import { numberFormat } from '@energidata/formatting'
import i18n from '@libs/internationalization'

export type ColumnChartData = {
	config?: Highcharts.Options;
	title?: string;
	subTitle?: string;
	categories: string[];
	data: [{
        name: string;
        y: number;
    }][];
	rotateYLabels: boolean;
	comparison: ComparisonEnum;
	showYAxis: boolean;
	legends: string[];
	unit: string;
	consumptionType: ConsumptionType;
}

export default ({ config, title, subTitle, categories, data, rotateYLabels, comparison, showYAxis, legends, unit, consumptionType }: ColumnChartData): Highcharts.Options => {
	const defaultConfig = {
		chart: {
			type: 'column',
			reflow: true,
			backgroundColor: 'transparent',
			height: '600px',
			marginTop: 200,
			spacingLeft: 0,
			spacingRight: 0,
			events: {
				// eslint-disable-next-line
				load: function(this, event: any) { //  event is of type Event, but it's painfully generic
					const min = event.target?.yAxis[0].min
					const max = event.target?.yAxis[0].max
					const dataMax = event.target?.yAxis[0].chart.yAxis[0].dataMax
					if (dataMax > max) {
						// eslint-disable-next-line
						event.target?.yAxis[0].setExtremes(null, dataMax)
					} else if (dataMax < min) {
						// eslint-disable-next-line
						event.target?.yAxis[0].setExtremes(dataMax, null)
					}
				}
			}
		},
		tooltip: {
			enabled: false
		},
		title: {
			style: {
				fontSize: '4em',
				color: '#fff'
			},
			text: title
		},
		subtitle: {
			style: {
				fontSize: '2em',
				color: '#fff'
			},
			text: subTitle
		},
		xAxis: {
			labels: {
				style: {
					fontSize: '1.25em',
					color: '#fff',
					textOverflow: 'none'
				}
			},
			lineWidth: 0,
			categories
		},
		yAxis: {
			title: {
				text: undefined
			},
			visible: showYAxis,
			labels: {
				style: {
					color: '#ffffff',
					fontSize: '16px'
				},
				formatter: function() {
					return consumptionType === 'cost' ? formatCurrency(unit, this.value as number) : numberFormat(i18n.global.locale, this.value as number) + ' ' + unit
				}
			}
		},
		legend: {
			itemStyle: {
				color: '#ffffff',
				fontSize: '16px',
				fontWeight: 'regular'
			},
			symbolRadius: 2,
			label: {
				style: {
					color: '#fff'
				}
			},
			floating: true,
			y: 50,
			enabled: comparison === ComparisonEnum.periodically,
			labelFormatter: function() {
				const hasStacked = data.length > 2
				const index = hasStacked ? this.index - 1 : this.index
				return legends[index]
			}
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			showInLegend: false,
			column: {
				groupPadding: data[0].length <= 2 ? 0 : 0.1,
				colorByPoint: data[0].length <= 2,
				colors: data[0].length <= 2 ? [config?.series?.[1].color, config?.series?.[0].color] : undefined // accumulated compare column is lighter color, taken from comparisonSeries
			}
		},
		series: [{ // default object, will most likely be overwritten!
			type: 'column',
			dataLabels: {
				enabled: !showYAxis, // Hide data labels when the y axis is visible and vice versa
				align: rotateYLabels ? 'left' : undefined,
				rotation: rotateYLabels ? -90 : 0,
				style: {
					fontFamily: 'Trebuchet MS',
					textOutline: 'transparent',
					color: '#fff',
					fontSize: '1.25em'
				},
				animation: true
			},
			borderWidth: 0,
			color: '#9bd4b5',
			data: [] as number[]
		}]
	} as Highcharts.Options

	if (config) mergeSeries(config, defaultConfig as Highcharts.Options, data)
	return defaultConfig as Highcharts.Options
}
