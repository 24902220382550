import { store } from '@libs/store'
import { hasLocalStorage } from '@libs/utils'

export const canLaunchApp = () => {
	const styles = document.body.style

	const neededSupport = [{
		name: 'js-proxies',
		support: !!window.Proxy
	}, {
		name: 'css-flex',
		support: typeof styles.flex === 'string'
	}, {
		name: 'svg',
		support: typeof SVGRect !== 'undefined'
	}, {
		name: 'cookies',
		support: hasLocalStorage()
	}]

	const niceSupport = [{
		name: 'css-backface-visibility',
		description: 'sharedTexts.missingFeatures.flipAnimation',
		support: typeof styles.backfaceVisibility === 'string' || typeof styles.webkitBackfaceVisibility === 'string'
	}, {
		name: 'feColorMatrix',
		description: 'sharedTexts.missingFeatures.saturation',
		support: typeof SVGFEColorMatrixElement !== 'undefined'
	}]

	store.setData('featureSupport.needed', neededSupport)
	store.setData('featureSupport.nice', niceSupport)

	const fullSupport = neededSupport.every((val) => val.support)

	if (!fullSupport) {
		const list = document.getElementById('unsupported-browser__list') // public/index.html

		if (!list) throw Error('Unsupported list element not found')

		neededSupport.forEach(feature => {
			const support = document.createElement('li')
			support.append(`${feature.support ? '✔️' : '❌'} ${feature.name.replace('-', ' ')}`)
			list.appendChild(support)
		})
	}

	return fullSupport
}
