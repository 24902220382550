import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Infoboard from '../views/Infoboard.vue'
import PincodePage from '../views/PincodePage.vue'
import Home from '../views/Home.vue'
import { getCompanyData } from '@/assets/libs/requests'

const noAuth: string[] = ['Pincode', 'Home']

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Pincode',
		component: PincodePage
	},
	{
		path: '/test',
		name: 'Home',
		component: Home
	},
	{
		path: '/infoboard',
		name: 'Infoboard',
		component: Infoboard
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async(to, _from, next) => {
	if (!to.name) next({ name: 'Pincode' })

	if (noAuth.includes(to.name as string)) next()
	else {
		const authTest = await getCompanyData()

		if (authTest.success) next()
		else next({ name: 'Pincode' })
	}
})

export default router
