import Axios, { AxiosRequestConfig } from 'axios'
import { addSearchParam, cloneObj } from '@libs/utils'

export const settings = {
	uuid: ''
}

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
    $http: Function;
		$httpEasy: Function;
  }
}

const axiosDefaultConfig: AxiosRequestConfig = {
	baseURL: process.env.VUE_APP_INFOBOARD_SERVICE,
	method: 'GET'
}

export const request = async(config: AxiosRequestConfig, cacheBust?: boolean) => {
	if (!config?.url) throw new Error('No url specified, request aborted')

	const _config = cloneObj(config)
	_config.headers = { authorization: 'Bearer ' + localStorage.getItem('authorization'), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC' }

	if (cacheBust) _config.url = addSearchParam(_config.url, 't', Math.random())

	_config.url = addSearchParam(_config.url, 'apikey', process.env.VUE_APP_API_KEY)

	const requestConfig = { ...axiosDefaultConfig, ..._config }

	return Axios(requestConfig)
}

export type ResponseObj = {
	data: null | {} | [];
	success: boolean;
}

export const requestEasy = async(config: AxiosRequestConfig): Promise<ResponseObj> => {
	const response = await request(config).catch(e => {
		console.error('Request() Failed:', e)
		return e
	})

	const responseObj = {
		data: null,
		success: false
	}

	responseObj.data = response.data || response
	responseObj.success = response && response.status === 200

	return responseObj
}

/**
 * Requests
 */
export const getInfoboard = async(): Promise<ResponseObj> =>
	requestEasy({ url: 'infoboards/' })

export const getRotation = async(rotationId: number): Promise<ResponseObj> =>
	requestEasy({ url: `rotations/${rotationId}/consumption` })

export const getCompanyData = async(): Promise<ResponseObj> =>
	requestEasy({ url: '/companies/' })

export const pingInfoboard = async(timesRotated: number): Promise<ResponseObj> =>
	requestEasy({ url: `ping/${timesRotated}` })

export const swapPincode = async(pin: string): Promise<ResponseObj> =>
	requestEasy({ url: `infoboards/swap/${pin}` })
