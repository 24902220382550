import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "container__body__missing-features"
}
const _hoisted_3 = { class: "base-margin--top-large controls controls--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Device = _resolveComponent("Device")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Device, null, {
      header: _withCtx(() => [
        _createVNode(_component_FontAwesomeIcon, { icon: ['fal', 'check'] }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('sharedTexts.success')) + "!", 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('sharedTexts.compatibleUnit')), 1),
        (_ctx.computedFailedNiceSupport.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('sharedTexts.missingFeatures.title')), 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedFailedNiceSupport, ({ name, description }) => {
                  return (_openBlock(), _createElementBlock("li", { key: name }, _toDisplayString(name) + " (" + _toDisplayString(_ctx.$t(description)) + ") ", 1))
                }), 128))
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('sharedTexts.compatabilityDisclaimer')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (this.$router.push('/')), ["stop","prevent"])),
            class: "link"
          }, _toDisplayString(_ctx.$t('sharedTexts.pincodeLink')), 1)
        ])
      ]),
      _: 1
    })
  ]))
}