import { ComparisonEnum, Rotation } from '../interfaces/interfaces'
import { ColumnChartData } from '@libs/models/charts/columnChart'
import { PieChartData } from '@libs/models/charts/pieChart'
import { formatCategories, formatEqualTo, formatSubTitle, formatTitle, formatLegends } from '@libs/models/formatters'
import { findChart } from '@/assets/libs/models/charts/charts'

export const getEqualTo = (rotation: Rotation): string => {
	const throwError = (rotation: Rotation) => {
		console.error('getEqualTo: cannot get equalTo, invalid arguments', {
			type: rotation.description.equalTo.type,
			value: rotation.description.equalTo.value,
			isProduction: rotation.isProduction
		})

		return ''
	}

	return rotation.description.equalTo.type && !isNaN(rotation.description.equalTo.value) && rotation.isProduction !== undefined
		? formatEqualTo(rotation)
		: throwError(rotation)
}

const getData = (data: Rotation, transformer: Function) => { // needs to be formatted for grouping
	const rtn = [transformer(data, 'currentData')]
	if (data.comparison === ComparisonEnum.periodically || data.comparison === ComparisonEnum.accumulated) { // Only push reference columns if we actually have reference data
		if (data.chart.comparisonPartialData?.some(value => value)) rtn.push(transformer(data, 'comparisonPartialData'))
		if (data.chart.comparisonDeltaData?.some(value => value)) rtn.push(transformer(data, 'comparisonDeltaData'))
	}
	return rtn
}

const createChart = (data: Rotation, locale: string): Highcharts.Options => {
	const chartObj = findChart(data.rotationType, data.energyType)
	const title = formatTitle(data.rotationType, data.energyType, data.consumptionType)
	const subTitle = formatSubTitle(data, locale)
	if ((data.rotationType === 'single') && data.comparison === ComparisonEnum.accumulated) {
		// @ts-ignore
		chartObj.config?.series?.[0].stacking = 'stacking'
		// @ts-ignore
		chartObj.config?.series?.[2].pointPlacement = 0
		// chartObj.config?.series?.[1].dataLabels.enabled = true
		// @ts-ignore
		chartObj.config?.series?.[1].opacity = 0.8
		// chartObj.config?.series?.[2].stacking = 'stacking'


	} else if (data.rotationType === 'single') {
		// @ts-ignore
		chartObj.config?.series?.[1].dataLabels.enabled = false
		// @ts-ignore
		chartObj.config?.series?.[0].stacking = undefined
		// @ts-ignore
		chartObj.config?.series?.[1].opacity = 1

	}

	return chartObj.chart({
		config: chartObj.config,
		title,
		subTitle,
		categories: formatCategories(data.chart.categories.labels, data.chart.categories.type, data.resolution, data.chart.categories.interval),
		data: getData(data, chartObj.dataTransformer),
		rotateYLabels: data.chart.categories.labels.length > 2,
		unit: data.unit,
		consumptionType: data.consumptionType,
		comparison: data.comparison,
		showYAxis: data.chart.showYAxis,
		legends: formatLegends(data)
	} as ColumnChartData | PieChartData)
}

export default createChart
