'use strict'

import { reactive } from 'vue'
import { StoreInterface } from './interfaces/interfaces'

import flatMap from 'array.prototype.flatmap'

/**
 * @example
 * store.getData('some.path.here')
 * store.setData('some.path.here', someValue)
 * // purely for debugging to see store state - do not modify it here.
 * store.getData('')
 *
 */
class Store implements StoreInterface {
	_defaultState: {}
	_state: {}
	_settings: {
		name: string;
		debug: boolean | null;
		debugFlag: string;
	};

	_lastUpdate: Date;

	constructor(defaultState: {}, debugFlag = 'user.isDeveloper', name = 'Store') {
		this._defaultState = defaultState
		this._state = this._resetStoreState()

		this._settings = {
			name,
			debug: null,
			debugFlag
		}

		this._lastUpdate = new Date() // @OBS: Only for debug purposes, do not use for events
	}

	/**
	 * @param {string} path dot notation path 'e.g.: 'user.id'
	 * @param {unknown} value value to set on path
	 * @example setData('user.id', 1)
	 */
	setData = (path: string, value: unknown) => {
		const existenceCheck = this._get(this._state, path)

		if (existenceCheck === undefined) return this._log('error', `cannot SET '${path}' as it has no default state (misspelling?)`)
		if (value === undefined) return this._log('error', `cannot SET '${path}' as undefined would invalidate the entry`)

		this._set(this._state, path, value)
		this._lastUpdate = new Date()

		if (this.debug) {
			const type = (typeof value).slice(0, 3)

			type === 'obj'
				? this._log('info', `SET [${type}] ${path}`, [value])
				: this._log('info', `SET ${path} to: [${type}] ${value}`)
		}
	}

	/**
	 * @param {string} path dot notation path 'e.g.: 'user.id'
	 * @returns {unknown} data from path, can be anything set by setData or _state default
	 * @example getData('user.id') // 1
	 */
	getData = (path: string): unknown => {
		const data = this._get(this._state, path)

		return data !== undefined
			? data
			: this._log('warn', `could not GET ${path}`)
	}

	/**
	 * Used to stringify circular references.
	 * Be specific as nested object(s) can be shown as unset or
	 * empty e.g. showData('user.id') and not showData('user') is possible
	 * @param {string} path dot notation path 'e.g.: 'user.id'
	 * @example showData('user.id') // 1
	 * @source // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
	 */
	showData = (path: string) => {
		const getCircularReplacer = () => {
			const seen = new WeakSet()
			return (_key: string, value: unknown) => {
				if (typeof value === 'object' && value !== null) {
					if (seen.has(value)) {
						return
					}
					seen.add(value)
				}
				return value
			}
		}

		this._log('warn', `(${path}) nested objects can be shown as unset or empty`)
		console.log(JSON.stringify(this.getData(path), getCircularReplacer(), 2))
	}

	/* PRIVATE */

	/**
	 * When resetting it may also break reactivity chain
	 * @private
	 * @returns Vue observable using cloned default state object
	 */
	_resetStoreState = () => reactive({ ...this._defaultState })

	/* Primary utilities */

	/**
	 * @param {'info' | 'warn' | 'error'} severity
	 * @param {string} message
	 * @param {array<unknown> | undefined} [val]
	 */
	_log = (severity: 'info' | 'warn' | 'error', message: string, val?: unknown[] | undefined) => {
		if (this.debug) {
			const colors = {
				default: 'color: #fff; display: block; padding: 2px 4px;',
				levels: {
					info: 'background: #013e68;',
					warn: 'background: #b76b00;',
					error: 'background: #9f2d2d;'
				}
			}
			const color = `${colors.default} ${colors.levels[severity]}`
			const logType = console[severity] || console.log
			const singleLog = () => logType(`%c${this._settings.name}: ${message}`, color)
			const groupLog = () => {
				console.groupCollapsed(`%c${this._settings.name}: ${message} 🔎`, color + ' cursor: pointer;')

				val ? val.forEach(val => logType(val)) : singleLog()
				console.trace()
				console.groupEnd()
			}

			groupLog()
		}
	}

	/**
	 * String to Object lookup implementation, somewhat comparable to lodash.get
	 * @private
	 * @param {object} src
	 * @param {string} path
	 * @returns {unknown}
	 * @source https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_get = (src: any, path: string) => {
		const pathArray: string[] = Array.isArray(path) ? path : path.split('.').filter(key => key)
		const pathArrayFlat = flatMap(pathArray, (part: string) => typeof part === 'string' ? part.split('.') : part)

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return pathArrayFlat.reduce((obj: Record<string, any>, key: string) => obj && obj[key], src)
	}

	/**
	 * String to Object set implementation, somewhat comparable to lodash.set
	 * @private
	 * @param {object} src
	 * @param {string} path
	 * @param {unknown} val
	 * @returns {unknown}
	 * @source https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab
	 */
	_set = (src: Record<string, unknown>, keys: string | string[], val: unknown): void => {
		keys = Array.isArray(keys) ? keys : keys.split('.')
		if (keys.length > 1) {
			src[keys[0]] = src[keys[0]] || {}
			return this._set(src[keys[0]] as Record<string, unknown>, keys.slice(1), val)
		}
		src[keys[0]] = val
	}

	/* GETTERS AND SETTERS */

	/**
	 * Gets debug from _settings.debug, this exists to compute default state
	 * @returns {Boolean}
	 */
	get debug() {
		return this._settings.debug !== null
			? this._settings.debug
			: this.getData(this._settings.debugFlag) as boolean
	}

	/**
	 * Sets debug in _settings
	 */
	set debug(value: boolean) {
		this._settings.debug = value
	}

	/**
	 * Safeguard to avoid direct access to store state which was default functionality
	 * in the old store
	 */
	get state() {
		this._log('error', 'Depcrecated - use store.getData("path")')
		return null
	}

	/**
	 * Safeguard to avoid direct access to store state which was default functionality
	 * in the old store
	 */
	set state(_value) {
		this._log('error', 'Depcrecated - use store.setData("path") instead')
	}

	/**
	 * Lastupdate is used internally as _lastUpdate and should not be used or overwritten directly
	 */
	get lastUpdate() {
		return this._lastUpdate
	}

	/**
	 * Lastupdate is used internally as _lastUpdate and should not be used or overwritten directly
	 */
	set lastUpdate(_value) {
		this._log('error', 'lastUpdate is set internally')
	}
}

export default Store

export const store = new Store({
	featureSupport: {
		needed: [],
		nice: []
	},
	user: {
		isDeveloper: process.env.NODE_ENV === 'development'
	}
})
