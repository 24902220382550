
import { defineComponent } from 'vue'
import { swapPincode } from '@libs/requests'
import PinCode from '@/components/PinCode.vue'
import Device from '@/components/Device.vue'

export default defineComponent({
	name: 'PincodePage',
	components: {
		PinCode,
		Device
	},
	data() {
		return {
			pinError: false
		}
	},
	async created() {
		const pinInUrl = this.$route.query.pincode as string

		if (pinInUrl) {
			this.pincode('complete', pinInUrl)
		} else {
			const token = localStorage.getItem('authorization')

			if (token) this.$router.push('/infoboard')
		}
	},
	methods: {
		async pincode(type: string, pin: string) {
			if (type === 'complete') {
				const response = await swapPincode(pin)

				if (!response.success) {
					const pincodeComponent = this.$refs.pincode as typeof PinCode
					pincodeComponent.showError()
					return null
				}

				localStorage.setItem('authorization', response.data as string)
				this.$router.push('/infoboard')
			}
		}
	}
})
