export enum ComparisonEnum {
	accumulated = 'accumulated',
	periodically = 'periodically',
	budget = 'budget',
	yearToDate = 'yearToDate'
}

/**
 * Helper types
 */
export type Company = {
	name: string;
	address: string;
	brandImage: string;
}

export type Description = {
	title: string;
	savings: boolean;
	percentage: number;
	text: string;
}

export type EnergyTypes = {
	current: {
		solar: string;
		wind: string;
		water: string;
		heat: string;
		electricity: string;
		chargingPoint: string;
	};
	historic: {
		solar: string;
		wind: string;
		water: string;
		heat: string;
		electricity: string;
		chargingPoint: string;
	};
}

export type RotationChart = {
	categories: {
		type: string;
		labels: string[];
		interval: string;
		comparisonFullInterval: string;
		comparisonPartialInterval: string;
	};
	currentData: number[];
	comparisonPartialData?: number[];
	comparisonDeltaData: number[];
	showYAxis: boolean;
}

export type RotationDescription = {
	savings: boolean;
	changes: {
		type: string;
		value: number;
	};
	equalTo: {
		type: string;
		value: number;
	};
}

export type RotationType = 'single' | 'total'

export type ConsumptionType = 'energy' | 'cost' | 'CO2'

export type Infoboard = {
	companyName: string;
	infoboardLabel: string;
	rotationObjects: {
		id: number;
		type: string;
		isProduction: boolean;
		title: string;
		textField: string;
		rotationType: RotationType;
	}[];
	disabled: boolean;
	background: string;
	locale: string;
	backgroundBlurFactor: number;
	greyScale: number;
	infoboardDuration: number;
}

export type Rotation = { // Rotation data from rotation service
	resolution: string;
	unit: string;
	energyType: string;
	isProduction: false;
	period: string;
	comparison: string;
	rotationType: RotationType;
	consumptionType: ConsumptionType;
	clientVersion: number;
	timezoneWarning: boolean;

	company: Company;

	description: RotationDescription;
	chart: RotationChart;
}

export type InfoboardRotation = { // Rotation data from infoboard service
	id: number;
	title: string;
	textField: string;
	type: string;
	isProduction: boolean;
	rotationType: RotationType;
	data?: Rotation;
	errorCounter?: number;
	measurementSystem: number;
}

export interface StoreInterface {
	_defaultState: {};
	_state: {};
	_settings: {
		name: string;
		debug: boolean | null;
		debugFlag: string;
	};
	_lastUpdate: Date;
	setData: (path: string, value: unknown) => void;
	_log: (severity: 'info' | 'warn' | 'error', message: string, val?: unknown[] | undefined) => void;
	showData: (path: string) => void;
}
