import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "infoboard-company" }
const _hoisted_2 = {
  ref: "type",
  class: "infoboard-company__type"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "g-container g-container--pass-padding" }
const _hoisted_5 = { class: "g-text g-text--title" }
const _hoisted_6 = { class: "g-text" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.computedEnergyTypeLogo }, null, 8, _hoisted_3)
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.rotationName), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.textField), 1),
      _createElementVNode("img", {
        class: "infoboard-company__logo",
        src: _ctx.logo
      }, null, 8, _hoisted_7)
    ])
  ]))
}