<template>
  <router-view/>
</template>

<style lang="scss">
@import "~@styles/style.scss"; // import in one file only (like here)

#app {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: none;
}
</style>
