
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'DeviceComponent',
	mounted() {
		setTimeout(() => { // animation
			const tv = this.$refs.tv as HTMLElement
			return tv.classList.add('container--on')
		}, 0)
	},
	computed: {
		hasHeader() {
			return !!this.$slots.header
		}
	}
})
