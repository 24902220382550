import { EnergyTypes } from '@libs/interfaces/interfaces'

export const energyTypeColors: EnergyTypes = {
	current: {
		solar: '#e1db93',
		wind: '#e3ffe6',
		electricity: '#46a06e',
		water: '#69a0be',
		heat: '#f07d00',
		chargingPoint: '#46a06e'
	},
	historic: {
		solar: '#f5f0b8',
		wind: '#e6fae8',
		electricity: '#90c6a8',
		water: '#a5c6d8',
		heat: '#f6b166',
		chargingPoint: '#90c6a8'
	}
}
