export type KeyMatcher = {
	match: unknown[];
	noMatch: unknown[];
}
/**
 * KeyMatcher
 * returns Object that contains testArray split into match
 * and noMatch depending if they exist in src or not
 * @remark strings are lowercased
 * @returns ({match: string[], noMatch: string[]})
 * @example (['Foo'], {foo: 'bar', bar: 'foo'}) // {match: ['foo'], noMatch: []}
 * @example (['Foo', 'bar'], {foo: 'bar'}) // {match: ['foo'], noMatch: ['bar']}
 */
export const keyMatcher = (testArray: string[], src: { [key: string]: unknown } | string[]): KeyMatcher | null => {
	if (!Array.isArray(testArray)) {
		console.error('keyMatcher(): testArray is not an Array', testArray)
		return null
	}

	let testSrc = (Array.isArray(src) ? src : Object.keys(src))

	testSrc = testSrc.map(srcItem => srcItem.toLowerCase())

	return testArray.reduce((acc, item) => {
		if (typeof item === 'string') item = item.toLowerCase()

		testSrc.includes(item)
			? acc.match.push(item)
			: acc.noMatch.push(item)

		return acc
	}, { match: [], noMatch: [] } as KeyMatcher)
}

/**
 * Appending search parameters to URL fragments avoiding manual string manipulation
 * [string, string, string |number] => string
 */

export const addSearchParam = (urlFragment: string, name: string, val: string | number) => {
	const fragment = urlFragment.slice(0, 1) === '/' ? urlFragment : '/' + urlFragment
	const urlObj = new URL(`https://foo.bar${fragment}`)

	urlObj.searchParams.append(name, String(val))

	return urlObj.pathname + urlObj.search
}

/**
 * cloneObj
 * Shallow clones Object
 * {} => {}
 */
export const cloneObj = (obj: {}) => JSON.parse(JSON.stringify(obj))

export const isHTMLTagSupported = (tag: string) => toString.call(document.createElement(tag)) !== '[object HTMLUnknownElement]'

/**
 * cookiesEnabled
 */
export const hasLocalStorage = () => {
	try {
		window.localStorage.setItem('a', 'a')
		const stored = window.localStorage.getItem('a')
		return !!stored
	} catch (e) {
		return false
	}
}

export const arraySum = (arr: number[]) => arr.reduce((acc: number, cur: number) => {
	acc += cur
	return acc
}, 0)

// not cross browser
// /**
//  * setCookie
//  */
// export const setCookie = (name: string, value: string, expiryYears = 50) => {
// 	const expiration = new Date()
// 	expiration.setFullYear(expiration.getFullYear() + expiryYears)
// 	// eslint-disable-next-line
// 	document.cookie =`${name}=${value}; expires=${expiration.toUTCString()}`
// }

// /**
//  * getCookie
//  */
// export const getCookie = (cookieName: string) => {
// 	const cookies = document.cookie.split(';')
// 	const cookieNames = cookies.map((cookie) => cookie.split('=')[0])
// 	const cookieIndex = cookieNames.indexOf(cookieName)

// 	if (cookieIndex === -1) return null

// 	return cookies[cookieIndex].split('=')[1].split('expires')[0].trim()
// }

// /**
//  * deleteCookie
//  */
// export const deleteCookie = (cookieName: string) => {
// 	setCookie(cookieName, '', -100)
// }
