import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { canLaunchApp } from '@libs/compatability'
import { request, requestEasy } from '@libs/requests'
import i18n from '@libs/internationalization'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import '@libs/font-awesome-lib'

const setRefreshLoop = () => {
	const d = new Date()
	const millisToMidnight = () => (-d + d.setHours(24))

	setInterval(() => window.location.reload(), millisToMidnight())
}

const init = () => {
	setRefreshLoop()

	const app = createApp(App)

	app.config.globalProperties.$http = request
	app.config.globalProperties.$httpEasy = requestEasy

	app.use(router)
		.use(i18n)
		.component('FontAwesomeIcon', FontAwesomeIcon) // make globally accessible
		.component('FontAwesomeLayers', FontAwesomeLayers) // make globally accessible
		.mount('#app')
}

// Fallback

const fallback = () => {
	const notification = document.getElementById('unsupported-browser') // public/index.html

	if (notification) notification.style.display = 'flex' // Shows unsupported infoboard overlay
}

canLaunchApp() ? init() : fallback()
