
import { defineComponent } from 'vue'
import { store } from '@libs/store'
import { arraySum } from '@libs/utils'

import {
	Rotation,
	Company,
	Infoboard
} from '@libs/interfaces/interfaces'

import InfoboardCompany from '@/components/InfoboardCompany.vue'
import InfoboardDescription from '@/components/InfoboardDescription.vue'
import InfoboardChart from '@/components/InfoboardChart.vue'

import StackedIconInformation from '@/components/StackedIconInformation.vue'

import {
	getInfoboard,
	getRotation,
	getCompanyData,
	pingInfoboard
} from '@libs/requests'

export default defineComponent({
	name: 'InfoboardView',
	components: {
		InfoboardCompany,
		InfoboardChart,
		InfoboardDescription,
		StackedIconInformation
	},
	data() {
		return {
			infoboard: {} as Infoboard,
			rotations: {} as Rotation[],
			companyData: {} as Company,

			currentRotationIndex: 0,
			autoChange: true,

			error: '',
			errorCount: 0,
			errorLoad: false,

			rotateInterval: 0,
			fetchInterval: 0,
			updateInterval: 0,
			timesRotated: 0,
			currentVersion: 0
		}
	},
	computed: {
		computedChartHasData(): boolean {
			return this.computedCurrentRotation
				? !!arraySum(this.computedCurrentRotation?.chart.currentData)
				: false
		},
		computedError(): { icon: string[]; text: string} {
			return {
				icon: this.errorLoad ? ['fal', 'exclamation-triangle'] : ['fal', 'wifi'],
				text: this.errorLoad ? this.$t('sharedTexts.loadFatal') : this.$t('sharedTexts.networkErrorFatal')
			}
		},
		computedHardReload(): null | void {
			return this.errorCount >= 3
				? this.hardReload()
				: null
		},

		computedBackgroundStyle(): Record<string, string> {
			// background will be white if filter points to an invalid ID
			return {
				'background-image': `url('${this.infoboard.background}')`,
				filter: this.computedSupportsFilter ? 'url("#sharpBlur")' : 'none'
			}
		},

		computedSupportsFilter() {
			const niceSupport = store.getData('featureSupport.nice') as Record<string, unknown>[]
			const feColorMatrix = niceSupport.find(feature => feature.name === 'feColorMatrix')

			return feColorMatrix ? feColorMatrix.support : false
		},

		computedNoNetwork(): boolean {
			const hasInfoboard = this.infoboard && Object.keys(this.infoboard).length
			return (!hasInfoboard && this.errorCount > 0) || this.errorCount >= 2
		},

		computedOldData(): boolean {
			return this.errorCount > 0
		},
		computedTimezoneWarning(): boolean {
			if (!this.computedCurrentRotation) return false
			return this.computedCurrentRotation.timezoneWarning
		},

		computedCurrentRotation(): null | Rotation {
			return this.rotations.length
				? this.rotations[this.currentRotationIndex]
				: null
		},

		computedType(): string {
			return this.computedCurrentRotation
				? this.computedCurrentRotation.rotationType === 'single' ? this.computedCurrentRotation.energyType as string : this.computedCurrentRotation.rotationType
				: ''
		}
	},
	async mounted(): Promise<void> {
		if (this.$route.query.auto === 'false') this.autoChange = false
		if (this.$route.query.index) {
			this.currentRotationIndex = parseInt(this.$route.query.index as string)
		}

		if (!localStorage.getItem('authorization')) {
			console.error('Cannot load board, missing token')
			this.errorCount++
		}

		await this.reloadInfoboard()

		if (Object.keys(this.infoboard).length === 0) this.errorLoad = true

		this.resetUpdateInterval()
	},
	methods: {
		setInfoboardCount(count: number): void {
			this.currentRotationIndex = count
			if (this.autoChange) this.resetRotateInterval()
		},

		resetRotateInterval() {
			this.timesRotated++
			clearInterval(this.rotateInterval)

			this.rotateInterval = setInterval(() => {
				this.currentRotationIndex = this.currentRotationIndex === this.rotations.length - 1
					? 0
					: this.currentRotationIndex + 1
			}, this.infoboard.infoboardDuration)
		},

		resetUpdateInterval() {
			clearInterval(this.updateInterval)

			this.updateInterval = setInterval(() => {
				this.reloadInfoboard()
			}, 3600 * 1000)
		},

		hardReload() {
			location.reload()
		},

		async getCompanyData(): Promise<null | Company> {
			const response = await getCompanyData()

			if (!response.success) return null

			return response.data as null | Company
		},

		async getInfoboard(): Promise<Infoboard | null> {
			const response = await getInfoboard()

			if (!response.success) {
				return null
			}

			return response.data as Infoboard
		},

		async getRotation(rotationId: number): Promise<Rotation | null> {
			const response = await getRotation(rotationId)
			if (response.success) {
				return response.data as Rotation
			}
			return null
		},

		async reloadInfoboard() {
			const companyData = await this.getCompanyData()
			const infoboardData = await this.getInfoboard()

			this.errorLoad = false

			if (!(companyData && infoboardData)) {
				this.errorCount++
				return null
			}
			this.companyData = companyData
			this.infoboard = infoboardData
			this.$i18n.locale = infoboardData.locale

			const rotationPromises = infoboardData.rotationObjects.map(rotation => this.getRotation(rotation.id))
			this.rotations = await Promise.all(rotationPromises) as Rotation[]

			await pingInfoboard(this.timesRotated)
			this.timesRotated = 0 // Is maintained as a tællerstand in the backend, so it is reset here

			if (this.rotations[0].clientVersion && this.currentVersion !== this.rotations[0].clientVersion && this.currentVersion > 0) { // If the backend tells there is a new client version, force-refresh the browser.
				this.currentVersion = this.rotations[0].clientVersion
				this.hardReload()
			}
			this.currentVersion = this.rotations[0].clientVersion

			if (this.autoChange) this.resetRotateInterval()
			this.errorCount = 0
		},

		signOutClicked() {
			localStorage.removeItem('authorization')
			this.$router.push('/')
		}
	}
})
