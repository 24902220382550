import i18n from '@libs/internationalization'
import ColumnChart from '@libs/models/charts/columnChart'
import PieChart from '@libs/models/charts/pieChart'
import { energyTypeColors } from '@libs/models/energyTypeColors'
import { EnergyTypes, Rotation, RotationType } from '@libs/interfaces/interfaces'
import Highcharts, { DataLabelsFormatterCallbackFunction } from 'highcharts'
import { formatAxisNumber, formatCurrency, standardDataTransformer } from '@libs/models/formatters'

const deltaComparisonColumnConfig = (color: string): Highcharts.SeriesOptionsType => ({
	type: 'column', // Highchart.options requires type to be set for other properties to exist
	name: 'deltaColumn',
	color,
	opacity: 0.8,
	dataLabels: {
		enabled: false
	},
	stacking: 'normal',
	showInLegend: false
})

const comparisonColumnConfig = (color: string): Highcharts.SeriesOptionsType => ({
	type: 'column', // Highchart.options requires type to be set for other properties to exist
	name: 'comparisonColumn',
	color,
	dataLabels: {
		enabled: false,
		formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
	},
	stacking: 'normal'
})

const pieConfig: Highcharts.Options = {
	series: [{
		type: 'pie',
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		}
	}]
}

const electricityConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.electricity
	},
	comparisonColumnConfig(energyTypeColors.historic.electricity),
	deltaComparisonColumnConfig(energyTypeColors.historic.electricity)]
}

const solarConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.solar
	},
	comparisonColumnConfig(energyTypeColors.historic.solar),
	deltaComparisonColumnConfig(energyTypeColors.historic.solar)]
}

const windConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.wind
	},
	comparisonColumnConfig(energyTypeColors.historic.wind),
	deltaComparisonColumnConfig(energyTypeColors.historic.wind)]
}

const waterConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.water
	},
	comparisonColumnConfig(energyTypeColors.historic.water),
	deltaComparisonColumnConfig(energyTypeColors.historic.water)]
}

const heatConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.heat
	},
	comparisonColumnConfig(energyTypeColors.historic.heat),
	deltaComparisonColumnConfig(energyTypeColors.historic.heat)]
}

const chargingPointConfig: Highcharts.Options = {
	series: [{
		type: 'column',
		name: 'primaryColumn', // Highchart.options requires type to be set for other properties to exist
		dataLabels: {
			formatter: formatAxisNumber as DataLabelsFormatterCallbackFunction
		},
		color: energyTypeColors.current.chargingPoint
	},
	comparisonColumnConfig(energyTypeColors.historic.chargingPoint),
	deltaComparisonColumnConfig(energyTypeColors.historic.chargingPoint)]
}

export type Charts = {
	[index: string]: {
		config: Highcharts.Options;
		chart: Function;
		dataTransformer: Function;
	};
}

export const charts: Charts = {
	electricity: {
		config: electricityConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	water: {
		config: waterConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	solar: {
		config: solarConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	heat: {
		config: heatConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	wind: {
		config: windConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	chargingPoint: {
		config: chargingPointConfig,
		chart: ColumnChart,
		dataTransformer: standardDataTransformer
	},
	total: {
		config: pieConfig,
		chart: PieChart,
		dataTransformer: (data: Rotation) => data.chart.categories.labels.map((category: string, index) => ({
			name: i18n.global.t(`sharedTexts.energyTypes.${category}`),
			y: data.chart.currentData[index],
			color: energyTypeColors.current[category as keyof EnergyTypes['current']],
			formatter: data.consumptionType === 'cost'
				? formatCurrency(data.unit, data.chart.currentData[index])
				: () => null,
			unit: data.unit
		}))
	}
}

export const findChart = (rotationType: RotationType, energyType: string | string[]) =>
	charts[rotationType === 'single' ? energyType as string : rotationType]

export const mergeSeries = (config: Highcharts.Options, defaultConfig: Highcharts.Options, data: unknown[][]) => {
	if (config?.series && defaultConfig.series) { // Highcharts does not like to merge series
		const series = defaultConfig.series
		const seriesTemplate = defaultConfig.series[0]

		data.forEach((seriesData, i) => {
			const configSeries = config.series?.[i]
			if (!(series && configSeries)) return

			series[i] = Highcharts.merge(seriesTemplate, configSeries)

			// eslint-disable-next-line
			// @ts-ignore
			series[i].data = seriesData
		})

		defaultConfig.series.reverse()
	}
}
