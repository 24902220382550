import daDK from '@/assets/internationalization/da-DK.json'
import deDE from '@/assets/internationalization/de-DE.json'
import enGB from '@/assets/internationalization/en-GB.json'
import svSE from '@/assets/internationalization/sv-SE.json'
import { createI18n } from 'vue-i18n'

const isProductionENV = process.env.NODE_ENV === 'production'

const i18n = createI18n({
	locale: navigator.language,
	fallbackLocale: 'en-GB',
	silentFallbackWarn: isProductionENV,
	silentTranslationWarn: isProductionENV,
	messages: {
		'da-DK': daDK,
		'de-DE': deDE,
		'en-GB': enGB,
		'sv-SE': svSE
	}
})

export default i18n
