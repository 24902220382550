
import CountUp from '@/components/CountUp.vue'
import { store } from '@libs/store'
import { defineComponent } from 'vue'
import { Rotation } from '@libs/interfaces/interfaces'
import { getEqualTo } from '@libs/models/rotations'
import { currencySymbol, normalizeArrayWithCurrency, numberFormatterManualDigits } from '@energidata/formatting'
import { formatCurrencyUnitToHighest } from '@/assets/libs/models/formatters'

export default defineComponent({
	name: 'InfoboardDescription',
	props: {
		rotation: {
			type: Object as () => Rotation,
			required: true
		},
		locale: {
			type: String,
			default: 'en-GB'
		}
	},
	components: {
		CountUp
	},
	computed: {
		computedHasNoReferenceData(): boolean {
			return !this.rotation.chart.comparisonPartialData?.some(value => value) && this.rotation.comparison === 'periodically'
		},
		computedUnit(): string {
			if (this.rotation.description.changes.type === 'percentage') return '%'

			return this.rotation.description.changes.value >= 1E6
				? formatCurrencyUnitToHighest(
					this.computedLocale,
					this.rotation.unit,
					[this.rotation.description.changes.value]
				).scaledSymbol
				: currencySymbol(this.computedLocale, this.rotation.unit, { display: 'symbol' })
		},
		computedCountUpFormatter(): ((value: number) => string) | null {
			if (this.rotation.description.changes.type === 'percentage') {
				return numberFormatterManualDigits(
					this.computedLocale,
					{ maximumFractionDigits: 0 }
				)
			}

			const decimals = this.rotation.description.changes.value >= 1E6 ? 1 : 0

			return (val: number): string => {
				if (decimals) {
					val = normalizeArrayWithCurrency(
						this.computedLocale,
						this.rotation.unit,
						[this.rotation.description.changes.value, val]
					).values[1]
				}

				return numberFormatterManualDigits(
					this.computedLocale, {
						minimumFractionDigits: decimals,
						maximumFractionDigits: decimals
					}
				)(val)
			}
		},
		computedAnimatedFlip() {
			const niceSupport = store.getData('featureSupport.nice') as Record<string, unknown>[]
			const backfaceVisibility = niceSupport.find(feature => feature.name === 'css-backface-visibility')

			return backfaceVisibility ? backfaceVisibility.support : false
		},
		computedEqualTo(): string {
			return this.rotation.description.equalTo.type
				? getEqualTo(this.rotation)
				: ''
		},
		computedSaving(): string {
			const state = this.rotation.description.savings

			return state
				? this.$t('sharedTexts.saving')
				: this.$t('sharedTexts.expense')
		},
		computedLocale(): string {
			return this.locale
		}
	},
	watch: {
		rotation() {
			this.$nextTick(() => { // should probably be a vue transition
				const text = this.$refs.text as HTMLElement | undefined
				if (text) {
					text.classList.remove('animation--fade')

					setTimeout(() => {
						text.classList.add('animation--fade')
					}, 0)
				}
			})
		}
	}
})
