import Highcharts from 'highcharts'
import i18n from '@libs/internationalization'
import { mergeSeries } from '@libs/models/charts/charts'
import { formatCurrency } from '@libs/models/formatters'
import { ConsumptionType } from '../../interfaces/interfaces'
import { numberFormat } from '@energidata/formatting'

export type PieChartData = {
	config?: Highcharts.Options;
	title?: string;
	subTitle?: string;
	categories: string[];
	data: [{
        name: string;
        y: number;
    }][];
	unit: string;
	consumptionType: ConsumptionType;
}

export default ({ config, title, subTitle, data, unit, consumptionType }: PieChartData): Highcharts.Options => {
	const defaultConfig = {
		chart: {
			type: 'pie',
			reflow: true,
			backgroundColor: 'transparent',
			height: '600px',
			marginLeft: 0,
			marginRight: 0,
			spacingLeft: 0,
			spacingRight: 0
		},
		tooltip: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		legend: {
			label: {
				style: {
					color: '#fff'
				}
			},
			symbolRadius: 2,
			itemStyle: {
				color: '#ffffff',
				fontSize: '16px',
				fontWeight: 'regular'
			},
			labelFormatter: function() {
				// @ts-ignore
				return `${this.name}: ${consumptionType === 'cost' ? formatCurrency(unit, this.y) : numberFormat(i18n.global.locale, this.y) + ' ' + unit}`
			}
		},
		title: {
			style: {
				fontSize: '4em',
				color: '#fff'
			},
			text: title
		},
		subtitle: {
			style: {
				fontSize: '2em',
				color: '#fff'
			},
			text: subTitle
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				showInLegend: true,
				startAngle: -90,
				endAngle: 90,
				center: ['50%', '90%'],
				dataLabels: {
					enabled: false
				},
				borderWidth: 0
			}
		},
		series: [{
			type: 'pie',
			name: 'Energy',
			innerSize: '50%',
			data: [] as PieChartData['data']
		}]
	} as Highcharts.Options

	if (config) mergeSeries(config, defaultConfig as Highcharts.Options, data)

	return defaultConfig as unknown as Highcharts.Options
}
