/**
 * PREFACE
 * Font Awesome Library should be instantiated only once, but the current
 * structure does not allow this. Therefore a library is being pre-packed
 * here and then imported into components that need it.
 *
 * Please note that some icons are still handled by the old installation
 * of font-awesome and are not handled here! Please refer to the list
 * being added to the library and whether or not vue-font-awesome is being
 * used or not.
 *
 * If the answer is yes to both, the icon comes from here :)
 *
 * HOW TO USE
 * Import the icons from an icon package and add those icons to the library.
 * If you do not add them to the library here you cannot use them.
 *
 * ps: Refrain from adding an entire icons package, you do not need several
 * thousand icons to bloat the software ;)
 *
 * USEFUL LINKS
 * http://blog.fontawesome.io/2014/05/19/stacking-text-and-icons
 */

// import { // Custom
//   buildings,
//   group
// } from './custom-icons'

import { // Light
	faCheckCircle as falCheckCircle, // avoid collision between fas/far
	faExclamationCircle as falExclamationCircle,
	faWifi as falWifi,
	faCircle as falCircle,
	faExclamationTriangle as falExclamationTriangle,
	faChartBar as falChartBar,
	faCheck as falCheck,
	faChartPie as falChartPie,
	faSignOutAlt as falSignOutAlt,
	faSlash as falSlash
} from '@fortawesome/pro-light-svg-icons'

import { // Duotone
	faCircle as fadCircle,
	faSpinnerThird as fadSpinnerThird,
	faPlug as fadPlug
} from '@fortawesome/pro-duotone-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
	// Fal
	falCheckCircle,
	falExclamationCircle,
	falWifi,
	falCircle,
	falExclamationTriangle,
	falChartBar,
	falCheck,
	falChartPie,
	falSignOutAlt,
	falSlash,
	// Duotone
	fadCircle,
	fadSpinnerThird,
	fadPlug
)
