
import { defineComponent } from 'vue'

type PinCode = {
	value: string;
}

export default defineComponent({
	name: 'PinCode',
	props: {
		numberOfFields: {
			type: Number,
			default: 1
		},
		maxLength: {
			type: Number,
			default: 1
		},
		disabled: {
			type: Boolean,
			default: false
		},
		pin: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			fields: [] as PinCode[],
			showErrorState: false
		}
	},
	computed: {
		computedPinCode(): string {
			return this.fields.map(field => field.value).join('')
		}
	},
	methods: {
		showError() {
			this.showErrorState = true
		},
		isUndefined(val: string) {
			return val === '' || val === undefined || val === null
		},
		paste(clipboardEvent: ClipboardEvent, index: number) {
			if (clipboardEvent.clipboardData) {
				const text = clipboardEvent.clipboardData.getData('text').trim().split('')

				this.setPinCode(text)
				const maxFields = this.fields.length
				const max = index + text.length
				const moveTo = max >= maxFields ? maxFields : max
				this.moveToInput(moveTo)
			}
		},
		setPinCode(pin: string | string[], index = 0) {
			this.fields.forEach((field, indx) => {
				if (indx >= index) {
					field.value = pin[indx]
				}
			})
		},
		clearPincode() {
			this.fields.forEach((field) => {
				field.value = ''
			})
		},
		inputKeyPress(evt: KeyboardEvent, index: number) {
			if (evt.code !== 'Backspace') return

			const input = evt.target as HTMLInputElement

			if (input !== undefined && this.isUndefined(input.value)) this.moveToInput(index - 1)
		},
		inputSelected(val: string) {
			if (this.isUndefined(val)) this.moveToInput()
		},
		moveToInput(goto = undefined as undefined | number) {
			const index = goto !== undefined ? goto : this.fields.findIndex(field => this.isUndefined(field.value))
			const input = this.$refs[`input-${index}`] as HTMLInputElement

			if (input && input.focus) {
				input.focus()
				return true
			} else return false
		},
		pinComplete() {
			this.$emit('pincode', 'complete', this.computedPinCode.toLowerCase())
		}
	},
	watch: {
		pin: {
			handler(pin: string) {
				this.$nextTick(() => this.setPinCode(pin))
			},
			immediate: true
		},
		numberOfFields: {
			handler(val: number) {
				this.fields = Array(val).fill(null).map(_item => ({ value: '' })) as PinCode[]
			},
			immediate: true
		},
		computedPinCode(pin: string) {
			this.showErrorState = false
			if (pin.length >= this.numberOfFields) this.pinComplete()
		}
	}
})
