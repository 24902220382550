import i18n from '@libs/internationalization'
import { ComparisonEnum, ConsumptionType, Rotation, RotationType } from '../interfaces/interfaces'
import { PointLabelObject } from 'highcharts'
import { currencyFormatter, normalizeArrayWithCurrency, numberFormat, numberFormatterManualDigits, currencySymbol, dateTimeFormatter, DateTimeFormatOptionsWithTZ, NumberFormatBaseOptions } from '@energidata/formatting'
import { Interval } from 'luxon'

const standardOptions = (value: number) => ({
	minimumFractionDigits: value ? String(Math.round(value)).length === 1 ? 1 : 0 : 0,
	maximumFractionDigits: value ? String(Math.round(value)).length === 1 ? 1 : 0 : 0,
	notation: value >= 1E6 ? 'compact' : 'standard'
}) as NumberFormatBaseOptions

// Formats the displayed number in chart
export const formatter = (value: number, unit = ''): string => {
	const internalFormatter = numberFormatterManualDigits(i18n.global.locale, standardOptions(value))

	return internalFormatter(value) + ' ' + unit
}

export const formatCurrency = (unit: string, value: number) => {
	return currencyFormatter(i18n.global.locale, unit, value, standardOptions(value))
}

// @ts-ignore
export function formatAxisNumber(this: PointLabelObject, options) {
	const value = this.y as number
	const point = this.point as PointLabelObject['point'] & { unit: string; options: {consumptionType: ConsumptionType; comparison: ComparisonEnum} }
	if (point.options.comparison === ComparisonEnum.periodically) {
		options.verticalAlign = 'top'
		options.y = -10
	}
	return point.options.consumptionType === 'cost'
		? formatCurrency(point.unit, value)
		: formatter(value, point.unit)
}

// Preliminary transformer that change how the data is structured, if you want to add any kind of data to the point object, do it here as it (probably) won't be available elsewhere
export const standardDataTransformer = (data: Rotation, dataLabel: 'currentData' | 'comparisonDeltaData') => {
	const dataArr = data?.chart?.[dataLabel]

	return dataArr
		? dataArr.map((value: number) => ({
			y: value !== 0 && value >= 0 ? value : null,
			unit: data.unit,
			consumptionType: data.consumptionType,
			comparison: data.comparison
		}))
		: []
}

export const formatCategories = (categories: string[], type: string, resolution: string, interval: string) => categories.map((category, index) => {
	if (type === 'translation') {
		const formatOptions: DateTimeFormatOptionsWithTZ = { hour: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC' }
		if (resolution === 'P1D' || resolution === 'P1Y') return i18n.global.t(`sharedTexts.time.${category}`)
	} else if (type === 'prefixWeek') return i18n.global.t('sharedTexts.time.week', { week: category })
	else return category
})

// Something something to get like ['2020', '2021'] for legend
export const formatLegends = (data: Rotation) => {
	if (data.comparison !== ComparisonEnum.periodically) return []
	if (data.resolution === 'PT1H') {
		return [i18n.global.t('sharedTexts.time.SameDayWeekBefore'), i18n.global.t('sharedTexts.time.CurrentDay')]
	}
	else {
		if (data.chart.comparisonPartialData?.some(value => value)) {
			const compYearStart = Interval.fromISO(data.chart.categories.comparisonFullInterval)?.start.year
			const compYearEnd = Interval.fromISO(data.chart.categories.comparisonFullInterval)?.end.year
			const actualYearStart = Interval.fromISO(data.chart.categories.interval)?.start.year
			const actualYearEnd = Interval.fromISO(data.chart.categories.interval)?.end.year
			const compYear = compYearStart !== compYearEnd ? `${compYearStart} - ${compYearEnd}` : compYearStart
			const actualYear = actualYearStart !== actualYearEnd ? `${actualYearStart} - ${actualYearEnd}` : actualYearStart
			return [compYear, actualYear]
		} else {
			const actualYearStart = Interval.fromISO(data.chart.categories.interval)?.start.year
			const actualYearEnd = Interval.fromISO(data.chart.categories.interval)?.end.year
			const actualYear = actualYearStart !== actualYearEnd ? `${actualYearStart} - ${actualYearEnd}` : actualYearStart
			return [actualYear]
		}
	}
}

export const formatTitle = (rotationType: RotationType, energyType: string | string[], consumptionType: ConsumptionType) => rotationType === 'single'
	? i18n.global.t(`sharedTexts.energyTypes.${energyType}`)
	: i18n.global.t(`sharedTexts.energyTypes.${rotationType}.${consumptionType}`)

export const formatSubTitle = (data: Rotation, locale: string) => {
	if (data.rotationType === 'single') {
		if (data.period === 'month') {
			if (data.energyType === 'chargingPoint') {
				return i18n.global.t(`sharedTexts.comparisons.${data.comparison}.${data.resolution}.chargingPoint`, { count: data.chart.currentData.length })
			} else {
				return i18n.global.t(`sharedTexts.comparisons.${data.comparison}.${data.resolution}.month`, { count: data.chart.currentData.length, unit: data.consumptionType === 'cost' ? currencySymbol(locale, data.unit, { display: 'symbol' }) : data.unit })
			}
		} else {
			return i18n.global.t(
				`sharedTexts.comparisons.${data.comparison}.${data.resolution}.${data.isProduction ? 'production' : 'consumption'}`,
				{ unit: data.consumptionType === 'cost' ? currencySymbol(locale, data.unit, { display: 'symbol' }) : data.unit, count: data.chart.currentData.length }
			)
		}
	} else {
		return i18n.global.t(`sharedTexts.time.${data.resolution}`)
	}
}

// Collapse me :)
export const findPeriod = (data: Rotation): string => {
	if (data.period === 'month') {
		return i18n.global.t('sharedTexts.time.TheLastXDays', { days: data.chart.currentData.length }).toLowerCase()
	}
	if (data.comparison === ComparisonEnum.yearToDate) {
		return i18n.global.t('sharedTexts.time.yearToDate')
	} else if (data.comparison === ComparisonEnum.periodically) {
		if (data.resolution === 'PT1H') return i18n.global.t('sharedTexts.time.CurrentDay').toLowerCase()
		else if (data.resolution === 'P1D') return i18n.global.t('sharedTexts.time.TheLast7Days').toLowerCase()
		else if (data.resolution === 'P1W') return i18n.global.t('sharedTexts.time.ThePeriod').toLowerCase()
		else if (data.resolution === 'P1M') return i18n.global.t('sharedTexts.time.TheLast12Months').toLowerCase()
		else return i18n.global.t('sharedTexts.time.ThePeriod').toLowerCase()
	} else if (data.comparison === ComparisonEnum.budget) {
		return i18n.global.t('sharedTexts.time.CurrentMonth').toLowerCase()
	} else if (data.comparison === ComparisonEnum.accumulated) {
		if (data.resolution === 'P1D') return i18n.global.t('sharedTexts.time.CurrentDay').toLowerCase()
		else if (data.resolution === 'P1W') return i18n.global.t('sharedTexts.time.TheLast7Days').toLowerCase()
		else if (data.resolution === 'P1M') return i18n.global.t('sharedTexts.time.TheLast30Days').toLowerCase()
		else if (data.resolution === 'P1Y') return i18n.global.t('sharedTexts.time.TheLast12Months').toLowerCase()
		return i18n.global.t('sharedTexts.time.ThePeriod').toLowerCase()
	} else return i18n.global.t('sharedTexts.time.ThePeriod').toLowerCase()
}

export const findTheConsumptionTypeText = (consumptionType: ConsumptionType) => {
	return i18n.global.t(`sharedTexts.theConsumptionTypeText.${consumptionType}`)
}

export const findConsumptionTypeText = (consumptionType: ConsumptionType) => {
	return i18n.global.t(`sharedTexts.consumptionTypeText.${consumptionType}`)
}

export const formatEqualTo = (rotation: Rotation) => {
	return i18n.global.tc(
		`sharedTexts.relatesTo.${rotation.isProduction ? 'production' : 'consumption'}.${rotation.description.equalTo.type}`,
		rotation.description.equalTo.value === 1 ? 1 : 2, {
			theConsumptionTypeText: findTheConsumptionTypeText(rotation.consumptionType),
			consumptionTypeText: findConsumptionTypeText(rotation.consumptionType),
			period: findPeriod(rotation),
			amount: rotation.description.equalTo.value > 10
				? numberFormat(i18n.global.locale, rotation.description.equalTo.value)
				: rotation.description.equalTo.value
		}
	)
}

export const formatCurrencyUnitToHighest = (locale: string, unit: string, values: number[]) =>
	normalizeArrayWithCurrency(locale, unit, values)

export default {
	formatter,
	formatAxisNumber,
	standardDataTransformer,
	formatCategories
}
