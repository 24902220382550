
import { defineComponent } from 'vue'
import { Company } from '@libs/interfaces/interfaces'

export default defineComponent({
	name: 'InfoboardCompany',
	props: {
		type: {
			type: String,
			default: ''
		},
		rotationType: {
			type: String,
			default: ''
		},
		rotationName: {
			type: String,
			default: ''
		},
		textField: {
			type: String,
			default: ''
		},
		logo: {
			type: String,
			default: ''
		},
		companyData: {
			type: Object as () => Company,
			default: () => ({})
		}
	},
	data() {
		return {
			energyTypeIcons: {
				electricity: require('@images/energy-types/electricity.png'),
				water: require('@images/energy-types/water.png'),
				solar: require('@images/energy-types/solar.png'),
				heat: require('@images/energy-types/heat.png'),
				wind: require('@images/energy-types/wind.png'),
				chargingPoint: require('@images/energy-types/charging-point.png')
			} as {[key: string]: string}
		}
	},
	computed: {
		computedEnergyTypeLogo(): string {
			if (this.rotationType === 'single') {
				const icon = this.energyTypeIcons[this.type]

				if (!icon) console.error('could not find icon', this.type, 'in', Object.keys(this.energyTypeIcons))

				return icon
			} else {
				return require('@images/energy-types/total.png')
			}
		}
	},
	watch: {
		computedEnergyTypeLogo: {
			handler() {
				this.$nextTick(() => { // ref is possibly undefined unless deferred
					const ref = this.$refs.type as HTMLElement
					ref.classList.remove('animation--fade')

					setTimeout(() => ref.classList.add('animation--fade'), 0)
				})
			},
			immediate: true
		}
	}
})
