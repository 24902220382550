import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pincode-page" }
const _hoisted_2 = { class: "pincode-page__body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pincode-page__title" }
const _hoisted_5 = { class: "g-text--s g-container g-container--no-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PinCode = _resolveComponent("PinCode")!
  const _component_Device = _resolveComponent("Device")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Device, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require('@images/infoboard_logo.svg'),
            alt: "logo",
            class: "pincode-page__logo"
          }, null, 8, _hoisted_3),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('sharedTexts.pincodeLabel')) + ": ", 1),
          _createVNode(_component_PinCode, {
            ref: "pincode",
            "number-of-fields": 6,
            onPincode: _ctx.pincode
          }, null, 8, ["onPincode"]),
          _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.$t('sharedTexts.pincodeText')), 1)
        ])
      ]),
      _: 1
    })
  ]))
}